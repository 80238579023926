<template>
  <a-modal :confirm-loading="loading" title="创建域" :visible="visible" :width="720" @ok="ok" @cancel="reset">
    <a-form-model
      ref="form"
      :label-col="{ span: 4, style: { textAlign: 'left' } }"
      :model="form"
      :rules="rules"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-model-item label="域名称" prop="name">
        <a-input v-model="form.name" :max-length="128" placeholder="请输入域名称"></a-input>
      </a-form-model-item>
       <a-form-model-item label="所属组织" prop="organization_id">
        <organization-select v-model="form.organization_id" placeholder="请选择组织"></organization-select>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { createZone } from '@/api/zone'
import OrganizationSelect from '@/components/select/OrganizationSelect'

export default {
  name: 'CreateForm',
  components: { OrganizationSelect },
  data () {
    return {
      loading: false,
      visible: false,
      form: {},
      rules: {
        name: [{ message: '请输入域名称', required: true, trigger: 'blur' }],
        organization_id: [{ message: '请选择所属组织', required: true, trigger: 'change' }]
      }
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    reset () {
      this.$refs.form.resetFields()
      this.visible = false
    },
    ok () {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = { ...this.form }
          this.loading = true
          createZone(data).then(res => {
            this.$message.success(res.message)
            this.$emit('ok')
            this.reset()
          }).finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
